import axios from 'axios'
import {
  Grid,
  Modal,
  Button,
  Heading,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEnv } from '@praxis/component-runtime-env'
import { errorMsg } from '../../../globalConfig/common/ConstantData'
import { AdminContext } from '../../../globalConfig/AdminContext'
import { useContext } from 'react'

export const DeleteExpensePool = (props) => {
  const makeToast = ToastProvider.useToaster()
  const env = useEnv()
  const context = useContext(AdminContext)

  const handleClose = (props) => {
    props.setDeleteExpensePoolVisible(false)
  }

  const handleDeleteExpensePool = () => {
    axios
      .delete(
        `${env.camsCoreApiUrl}/expense_pool/${props.data.expense_type}?key=${env.apiKey}`
      )
      .then((res) => {
        console.log(`data deleted for expense type ${props.data.expense_type}`)
        context.fetchExpensePool()
        props.setDeleteExpensePoolVisible(false)
        makeToast({
          type: 'success',
          heading: 'Success',
          message: `Expense Type ${props.data.expense_type} is deleted successfully`,
        })
      })
      .catch((error) => {
        console.log('Error when deleting expense pool data', error)
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: errorMsg,
        })
      })
    handleClose(props)
  }

  return (
    <div>
      <Modal
        headingText="Delete Expense Pool"
        isVisible={props.deleteExpensePoolVisible}
        onRefuse={() => {
          handleClose(props)
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <Heading size={6}>
                {' '}
                Do you want to delete the Expense Type:{' '}
                {props.data.expense_type} ?
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button type="primary" onClick={handleDeleteExpensePool}>
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  handleClose(props)
                }}
              >
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}

export default DeleteExpensePool
