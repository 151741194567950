function amountFormatter(amount) {
  if (amount >= 0) {
    return amount?.toFixed(2)
  }
}

function currencyFormatter(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(amount))
}

function amountFormatters(amount) {
  // Ensure amount is a valid number
  if (typeof amount !== 'number' || isNaN(amount)) {
    return 0 // Return a default numeric value for invalid inputs
  }

  // Return the number rounded to two decimal places
  return parseFloat(amount.toFixed(2))
}

export { amountFormatter, currencyFormatter, amountFormatters }
