import axios from 'axios'
import {
  Button,
  Form,
  Grid,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import * as Yup from 'yup'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { AdminContext } from '../../../globalConfig/AdminContext'
import { useContext } from 'react'
import '@enterprise-ui/canvas-ui-css-datepicker'
import moment from 'moment'
import {
  errorMsg,
  contractStatusOptions,
  regionOptions,
} from '../../../globalConfig/common/ConstantData'

const handleClose = (props) => {
  props.setAddContractMetadataVisible(false)
}

export const AddContractMetadata = (props) => {
  const env = useEnv()
  const { session } = useAuth()
  const makeToast = ToastProvider.useToaster()
  const context = useContext(AdminContext)

  const initialValues = {
    //  default camType to Payable in the phase 1.
    camType: 'Payable',
    locationId: '',
    contractNo: '',
    contractStartDate: '',
    contractEndDate: '',
    contractStatus: 'A',
    contractName: '',
    region: '',
    prs: '',
    siteType: '',
    streetAddress: '',
    storeName: '',
    city: '',
    state: '',
    vendorNo: '',
    vendorName: '',
    emailId: '',
    operator: '',
    budgetRequirement: '',
    reconRequirement: '',
  }

  const schema = Yup.object({
    locationId: Yup.string()
      .required('Please Enter Location')
      .test('Fine', 'Location should be 5 chars', function (value) {
        var LocationLength = value?.toString().length
        if (LocationLength !== 5) {
          return false
        }
        return true
      }),
    contractNo: Yup.number()
      .required('Please Enter Contract No')
      .test('Fine', 'Contract No should be 7 digits', function (value) {
        var contractNoLength = value?.toString().length
        if (contractNoLength !== 7) {
          return false
        }
        return true
      }),
    contractName: Yup.string().required('Please Enter Contract Name'),
    contractStartDate: Yup.string().required(
      'Please Enter Contract Start Date'
    ),
    contractEndDate: Yup.string().required('Please Enter Contract End Date'),
    region: Yup.number()
      .nullable(true)
      .test('Fine', 'Region should be 3 digits or blank', function (value) {
        var regionLength = value ? value?.toString().length : 0
        if (regionLength !== 3 && regionLength !== 0) {
          return false
        }
        return true
      }),
    prs: Yup.string().required('Please Enter PRS'),
    siteType: Yup.string().required('Please Enter Site Type'),
    storeName: Yup.string().required('Please Enter Store Name'),
    streetAddress: Yup.string().required('Please Enter Street Address'),
    city: Yup.string().required('Please Enter City'),
    state: Yup.string().required('Please Enter State'),
    vendorNo: Yup.number().required('Please Enter Vendor No'),
    vendorName: Yup.string().required('Please Enter Vendor Name'),
    emailId: Yup.string()
      .required('Please Enter Email Id')
      .email('Enter Valid Email.'),
    operator: Yup.string().required('Please Enter Operator or Landlord'),
    budgetRequirement: Yup.string().required('Please Enter Budget Requirement'),
    reconRequirement: Yup.string().required('Please Enter Recon Requirment'),
  })

  const onSubmit = (values) => {
    const submitheaders = {
      'content-type': 'application/json',
    }

    const contractMetadataRequest = {
      contract_metadata_type: values.camType,
      contract_no: values.contractNo,
      contract_start_date: moment(
        values.contractStartDate,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD'),
      contract_end_date: moment(values.contractEndDate, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      ),
      contract_status: values.contractStatus,
      contract_name: values.contractName,
      location_id: values.locationId,
      loc_name: values.storeName,
      loc_address: values.streetAddress,
      loc_city: values.city,
      loc_state: values.state,
      region: values.region,
      prs: values.prs,
      site_type: values.siteType,
      vendor_no: values.vendorNo,
      vendor_name: values.vendorName,
      email_id: values.emailId,
      operator_or_landlord: values.operator,
      budget_requirement: values.budgetRequirement,
      recon_requirement: values.reconRequirement,
      create_user_id: session.userInfo.lanId,
    }

    axios
      .post(
        `${env.camsCoreApiUrl}/contract_metadata?key=${env.apiKey}`,
        contractMetadataRequest,
        {
          headers: submitheaders,
        }
      )
      .then((res) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'contract metadata is saved successfully',
        })
        console.log('data is saved for contract metadata')
        formik.resetForm()
        context.fetchContractMetadata()
        handleClose(props)
      })
      .catch((error) => {
        console.log('Error when saving contract metadata', error)
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: errorMsg,
        })
      })
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
  })

  return (
    <Modal
      headingText="Create Contract Metadata"
      isVisible={props.addContractMetadataVisible}
      onRefuse={() => {
        formik.resetForm()
        props.setAddContractMetadataVisible(false)
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'contractNo'}
                label={'contract no'}
                className="inputtype"
                type="number"
                onChange={(e) =>
                  formik.setFieldValue('contractNo', e.target.value)
                }
                value={formik.values.contractNo}
                error={
                  formik.touched.contractNo &&
                  formik.errors.contractNo !== undefined
                }
                errorText={formik.errors.contractNo}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'camType'}
                label={'CAM Payable / Rebill'}
                type="text"
                disabled
                onChange={(e) =>
                  formik.setFieldValue('camType', e.target.value)
                }
                value={formik.values.camType}
                className="inputtype hc-ta-left"
                error={
                  formik.touched.camType && formik.errors.camType !== undefined
                }
                errorText={formik.errors.camType}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'locationId'}
                label={'Location'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('locationId', e.target.value)
                }
                value={formik.values.locationId}
                error={
                  formik.touched.locationId &&
                  formik.errors.locationId !== undefined
                }
                errorText={formik.errors.locationId}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <DatePicker
                id="contractStartDate"
                label="Contract Start Date"
                placeholder="MM/DD/YYYY"
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value)
                }}
                value={formik.values.contractStartDate}
                error={
                  formik.touched.contractStartDate &&
                  formik.errors.contractStartDate !== undefined
                }
                errorText={formik.errors.contractStartDate}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <DatePicker
                id="contractEndDate"
                label="Contract End Date"
                placeholder="MM/DD/YYYY"
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value)
                }}
                value={formik.values.contractEndDate}
                error={
                  formik.touched.contractEndDate &&
                  formik.errors.contractEndDate !== undefined
                }
                errorText={formik.errors.contractEndDate}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'contractStatus'}
                label={'Contract Status'}
                type="select"
                disabled
                onUpdate={(id, value) => {
                  formik.setFieldValue('contractStatus', value)
                }}
                options={contractStatusOptions}
                value={formik.values.contractStatus}
                error={
                  formik.touched.contractStatus &&
                  formik.errors.contractStatus !== undefined
                }
                errorText={formik.errors.contractStatus}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'contractName'}
                label={'Contract Name'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('contractName', e.target.value)
                }
                value={formik.values.contractName}
                error={
                  formik.touched.contractName &&
                  formik.errors.contractName !== undefined
                }
                errorText={formik.errors.contractName}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'region'}
                label={'Region'}
                type="select"
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value)
                }}
                options={regionOptions}
                value={formik.values.region || ''}
                error={
                  formik.touched.region && formik.errors.region !== undefined
                }
                errorText={formik.errors.region}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'prs'}
                label={'PRS'}
                type="number"
                className="inputtype"
                onChange={(e) => formik.setFieldValue('prs', e.target.value)}
                value={formik.values.prs}
                error={formik.touched.prs && formik.errors.prs !== undefined}
                errorText={formik.errors.prs}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'siteType'}
                label={'Site Type'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('siteType', e.target.value)
                }
                value={formik.values.siteType}
                error={
                  formik.touched.siteType &&
                  formik.errors.siteType !== undefined
                }
                errorText={formik.errors.siteType}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'storeName'}
                label={'Store Name'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('storeName', e.target.value)
                }
                value={formik.values.storeName}
                error={
                  formik.touched.storeName &&
                  formik.errors.storeName !== undefined
                }
                errorText={formik.errors.storeName}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'streetAddress'}
                label={'Street Address'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('streetAddress', e.target.value)
                }
                value={formik.values.streetAddress}
                error={
                  formik.touched.streetAddress &&
                  formik.errors.streetAddress !== undefined
                }
                errorText={formik.errors.streetAddress}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'city'}
                label={'City'}
                type="text"
                onChange={(e) => formik.setFieldValue('city', e.target.value)}
                value={formik.values.city}
                error={formik.touched.city && formik.errors.city !== undefined}
                errorText={formik.errors.city}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'state'}
                label={'State'}
                type="text"
                onChange={(e) => formik.setFieldValue('state', e.target.value)}
                value={formik.values.state}
                error={
                  formik.touched.state && formik.errors.state !== undefined
                }
                errorText={formik.errors.state}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'vendorNo'}
                label={'Vendor#'}
                type="number"
                className="inputtype"
                onChange={(e) =>
                  formik.setFieldValue('vendorNo', e.target.value)
                }
                value={formik.values.vendorNo}
                error={
                  formik.touched.vendorNo &&
                  formik.errors.vendorNo !== undefined
                }
                errorText={formik.errors.vendorNo}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'vendorName'}
                label={'Vendor Name'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('vendorName', e.target.value)
                }
                value={formik.values.vendorName}
                error={
                  formik.touched.vendorName &&
                  formik.errors.vendorName !== undefined
                }
                errorText={formik.errors.vendorName}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'emailId'}
                label={'Email Id'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('emailId', e.target.value)
                }
                value={formik.values.emailId}
                error={
                  formik.touched.emailId && formik.errors.emailId !== undefined
                }
                errorText={formik.errors.emailId}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'operator'}
                label={'Operator / Landlord'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('operator', e.target.value)
                }
                value={formik.values.operator}
                error={
                  formik.touched.operator &&
                  formik.errors.operator !== undefined
                }
                errorText={formik.errors.operator}
              />
            </Grid.Item>

            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'budgetRequirement'}
                label={'Budget Requirement'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('budgetRequirement', e.target.value)
                }
                value={formik.values.budgetRequirement}
                error={
                  formik.touched.budgetRequirement &&
                  formik.errors.budgetRequirement !== undefined
                }
                errorText={formik.errors.budgetRequirement}
              />
            </Grid.Item>
            <Grid.Item xs={4} className="hc-pb-none">
              <Form.Field
                id={'reconRequirement'}
                label={'Recon Requirement'}
                type="text"
                onChange={(e) =>
                  formik.setFieldValue('reconRequirement', e.target.value)
                }
                value={formik.values.reconRequirement}
                error={
                  formik.touched.reconRequirement &&
                  formik.errors.reconRequirement !== undefined
                }
                errorText={formik.errors.reconRequirement}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item justify="flex-end" xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button type="submit">Save</Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    onClick={() => {
                      formik.resetForm()
                      handleClose(props)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Form>
    </Modal>
  )
}
